import "./notification.scss";
import React, { useEffect } from "react";

function Notification({ message, onClose }) {
    // Используем useEffect для установки таймера закрытия уведомления
    useEffect(() => {
        // Устанавливаем таймер для автоматического закрытия уведомления через 30 секунд
        const timer = setTimeout(() => {
            onClose(); // Закрываем уведомление
        }, 30000);

        // Очистка таймера при размонтировании компонента
        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div className="notification">
            <div className="notification__content">
                <p>{message}</p>
                <button onClick={onClose}>Закрыть</button>
            </div>
        </div>
    );
}

export default Notification;
