

import img1 from "../asset/mainImg/services/item_2.jpg"
import img2 from "../asset/mainImg/services/item_3.jpg"
import img3 from "../asset/mainImg/services/item_4.jpg"
import img4 from "../asset/mainImg/services/item_5.jpeg"
import img5 from "../asset/mainImg/services/item_6.jpg"
import img6 from "../asset/mainImg/services/item_7.jpg"
import img7 from "../asset/mainImg/services/item_8.jpg"
import img8 from "../asset/mainImg/services/item_9.png"

const servicesObj =[
    {
        "id": "1",
        "name": "Поверка счетчиков воды",
        "img": img1
    },
    {
        "id": "2",
        "name": "Замена счетчиков воды",
        "img": img2
    },
    {
        "id": "3",
        "name": "Установка счетчиков воды",
        "img": img3
    },
    {
        "id": "4",
        "name": "Поверка теплосчетчиков",
        "img": img4
    },
    {
        "id": "5",
        "name": "Поверка расходомеров",
        "img": img5
    },
    {
        "id": "8",
        "name": "Поверка тепловычислителей",
        "img": img6
    },
    {
        "id": "7",
        "name": "Поверка манометров",
        "img": img7
    },
    {
        "id": "8",
        "name": "Поверка преобразователей",
        "img": img8
    }
];

export {servicesObj};