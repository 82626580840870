import "./application.scss";
import React, { useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";

// Картинки
import userApplication from "../../asset/application/user.svg";
import phoneApplication from "../../asset/application/phone.svg";
import counterApplication from "../../asset/application/counterWater.svg";

function Application() {
  const [messageName, setMessageName] = useState("");
  const [messagePhone, setMessagePhone] = useState("");
  const [messageQuantity, setMessageQuantity] = useState("");

  const urlSend = `Заявка с сайта! \nИмя: ${messageName},\nНомер телефона: ${messagePhone},\nКоличество счетчиков: ${messageQuantity}`;

  // Функция для генерации хеша сообщения
  const generateHash = (text) => {
    return CryptoJS.SHA256(text).toString(CryptoJS.enc.Hex);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Генерируем хеш текущего сообщения
    const currentHash = generateHash(urlSend);
    const lastHash = localStorage.getItem('lastMessageHash');

    // Проверяем, если сообщение уже отправлено
    if (lastHash === currentHash) {
      alert("Вы уже отправили эту заявку.");
      return;
    }

    try {
      await axios.post("https://api.telegram.org/bot6638340620:AAFlpaHxSSnUuZrxJp5omh31hE9O0FITKQc/sendMessage", {
        chat_id: "-1001629929227",
        text: urlSend,
      });

      // Сохраняем хеш отправленного сообщения в localStorage
      localStorage.setItem('lastMessageHash', currentHash);

      alert("Уведомление успешно отправлено!");
      setMessageName("");
      setMessagePhone("");
      setMessageQuantity("");
    } catch (error) {
      console.error(error);
      alert("Ошибка при отправке уведомления!");
    }
  };

  return (
    <div className="application">
      <h2 className="body__title">Оставьте онлайн заявку</h2>
      <form onSubmit={handleSubmit}>
        <div className="application__form">
          <img src={userApplication} alt="userApplication" />
          <input
            value={messageName}
            onChange={(e) => setMessageName(e.target.value)}
            type="text"
            name="name"
            placeholder="Введите ваше имя"
            required
          />
        </div>
        <div className="application__form">
          <img src={phoneApplication} alt="phoneApplication" />
          <input
            value={messagePhone}
            onChange={(e) => setMessagePhone(e.target.value)}
            type="text"
            name="tel"
            placeholder="Введите ваш телефон"
            required
          />
        </div>
        <div className="application__form">
          <img src={counterApplication} alt="counterApplication" />
          <select value={messageQuantity} onChange={(e) => setMessageQuantity(e.target.value)} required>
            <option disabled="" selected="">Количество счетчиков</option>
            <option value="1">1 счетчик</option>
            <option value="2">2 счетчика</option>
            <option value="3">3 счетчика</option>
            <option value="4">4 счетчика</option>
            <option value="5">5 счетчиков</option>
            <option value="6">6 счетчиков</option>
            <option value="7">7 счетчиков</option>
            <option value="8">8 и более (скидка 20%)</option>
          </select>
        </div>
        <div className="application__submit"><input type="submit" /></div>
      </form>
    </div>
  );
}

export default Application;
