


// Компоненты
import ServicesItem from "../../components/Services/ServicesItem";
import {servicesObj} from "../../asset/servicesObj.js";
import "./amenities.scss"

function Amenities() {
    return (
      <>
      <main>
        <h2 className="body__title">Предоставляемые услуги</h2>
        <h5 className="amenities__subtitle">Наша компания занимается установкой, заменой, настройкой и поверкой счетчиков с выездом на объект. Работаем с приборами учета воды, тепла и газа, а также с термометрами, манометрами (устройства для измерения давления). При работе с водомерами используем мобильное сертифицированное метрологическое оборудование, а при работе с приборами учета тепла проводим исследования в собственной лаборатории. Сотрудничаем с физическими и юридическими лицами, выезжаем на объект и даем гарантию на выполненные работы.</h5>
        <section className="amenities__grid">
          <ServicesItem servicesObj={servicesObj}/>
        </section>
      </main>
      </>
    );
  }
  
  export default Amenities;