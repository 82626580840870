
import documentItem_1 from "../asset/mainImg/documents/документ о поверке.png";
import documentItem_2 from "../asset/mainImg/documents/протокол поверки.png";
import documentItem_3 from "../asset/mainImg/documents/регистрация поверенного счетчика.png";


const swiperDocList = [
    {
        "id": "1",
        "name": "Документ о поверке",
        "img": documentItem_1
    },
    {
        "id": "2",
        "name": "Протокол поверки",
        "img": documentItem_2
    },
    {
        "id": "3",
        "name": "Регистрация поверенного счетчика",
        "img": documentItem_3
    }
]

export {swiperDocList};