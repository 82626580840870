import "./modal.scss";
import axios from "axios";
import React, { useState } from "react";
import CryptoJS from "crypto-js";
import Notification from "./Notification";

// Картинки
import userApplication from "../../asset/application/user.svg";
import phoneApplication from "../../asset/application/phone.svg";
import counterApplication from "../../asset/application/counterWater.svg";

function Modal({ active, setActive, children }) {
    const [messageName, setMessageName] = useState("");
    const [messagePhone, setMessagePhone] = useState("");
    const [messageQuantity, setMessageQuantity] = useState("");
    const [notification, setNotification] = useState(null); // Состояние для уведомления

    // Определяем текущее время и день недели
    const now = new Date();
    const dayOfWeek = now.getDay(); // 0 (воскресенье) - 6 (суббота)
    const currentHour = now.getUTCHours() + 4; // Ульяновский часовой пояс (UTC+4)

    let responseTimeMessage = "Оператор свяжется с вами в течении ближайшего времени";

    // Если отправка в выходные
    if (dayOfWeek === 0 || dayOfWeek === 6) {
        responseTimeMessage = "Оператор свяжется с вами в ближайшее рабочее время ";
    }

    // Если время после 19:00
    if (currentHour >= 19) {
        responseTimeMessage = "Оператор свяжется с вами в рабочее время с 8:00 до 19:00";
    }

    const urlSend = `Заявка с сайта! \nИмя: ${messageName},\nНомер телефона: ${messagePhone},\nКоличество счетчиков: ${messageQuantity}`;

    // Функция для генерации хеша сообщения
    const generateHash = (text) => {
        return CryptoJS.SHA256(text).toString(CryptoJS.enc.Hex);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Генерируем хеш текущего сообщения
        const currentHash = generateHash(urlSend);
        const lastHash = localStorage.getItem('lastMessageHash');

        // Проверяем, если сообщение уже отправлено
        if (lastHash === currentHash) {
            setNotification(`Вы уже успешно отправили заявку, ${responseTimeMessage}`);
            return;
        }

        try {
            await axios.post("https://api.telegram.org/bot6638340620:AAFlpaHxSSnUuZrxJp5omh31hE9O0FITKQc/sendMessage", {
                chat_id: "-1001629929227",
                text: urlSend,
            });

            // Сохраняем хеш отправленного сообщения в localStorage
            localStorage.setItem('lastMessageHash', currentHash);

            setNotification(`Уведомление успешно отправлено! ${responseTimeMessage}`);
            setMessageName("");
            setMessagePhone("");
            setMessageQuantity("");
        } catch (error) {
            console.error(error);
            setNotification("Ошибка при отправке уведомления!");
        }
    };

    const closeNotification = () => {
        setNotification(null);
        setActive(false); // Закрыть окно вызова мастера при закрытии уведомления
    };

    return (
        <div className={active ? "modal active" : "modal"} onClick={() => setActive(false)}>
            <div className="modal__content" onClick={e => e.stopPropagation()}>
                {children}
                <form onSubmit={handleSubmit}>
                    <h2>Вызвать мастера</h2>
                    <div className="form__modal">
                        <img src={userApplication} alt="userApplication" />
                        <input
                            value={messageName}
                            onChange={(e) => setMessageName(e.target.value)}
                            type="text"
                            name="name"
                            placeholder="Введите ваше имя"
                            required
                        />
                    </div>
                    <div className="form__modal">
                        <img src={phoneApplication} alt="phoneApplication" />
                        <input
                            value={messagePhone}
                            onChange={(e) => setMessagePhone(e.target.value)}
                            type="text"
                            name="tel"
                            placeholder="Введите ваш телефон"
                            required
                        />
                    </div>
                    <div className="form__modal">
                        <img src={counterApplication} alt="counterApplication" />
                        <select value={messageQuantity} onChange={(e) => setMessageQuantity(e.target.value)} required>
                            <option disabled="" selected="">Количество счетчиков</option>
                            <option value="1">1 счетчик</option>
                            <option value="2">2 счетчика</option>
                            <option value="3">3 счетчика</option>
                            <option value="4">4 счетчика</option>
                            <option value="5">5 счетчиков</option>
                            <option value="6">6 счетчиков</option>
                            <option value="7">7 счетчиков</option>
                            <option value="8">8 и более (скидка 20%)</option>
                        </select>
                    </div>
                    <div className="modal__submit"><input type="submit" /></div>
                </form>
                {notification && (
                    <Notification message={notification} onClose={closeNotification} />
                )}
            </div>
        </div>
    );
}

export default Modal;
