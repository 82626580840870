

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y } from 'swiper/modules';

import "./swiperDocuments.scss";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

function SwiperDocuments({ swiperDocList }) {
  return (
    <div className='SwiperDocuments'>
      <Swiper
        // install Swiper modules
        // для slidesPerView={"auto"} необходимо прописать свойство .swiper-slide {width: auto!important;}
        modules={[Navigation, A11y]}
        spaceBetween={20}
        slidesPerView={"2"}
        breakpoints={{
          0:{
            slidesPerView:1
          },
          1215:{
            slidesPerView:2
          }
        }}
        navigation
        loop={true}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
        {swiperDocList?.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="swiperDocuments__item">
                <a className="swiperDocuments__item__img" href={item.img}><img src={item.img} alt="" /></a>
                <h3>{item.name}</h3>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
export default SwiperDocuments;
